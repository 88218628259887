<template>
  <Dialog @hide="closeDialog" style="width: 70%" v-model:visible="showDialog" header="添加招聘">
    <div class="card p-fluid">
      <div class="p-formgrid p-grid">
        <h6 style="font-weight: bold" class="p-col-12">岗位基本信息</h6>

        <div class="p-field p-col p-md-6">
          <label>职位名称：</label>
          <InputText @blur="change2" v-model="from.job"></InputText>
          <span class="change">{{ mas2 }}</span>
        </div>
        <div class="p-field p-col">
          <label>公司地址：</label>
          <InputText @blur="change3" v-model="from.addr"></InputText>
          <span class="change">{{ mas3 }}</span>
        </div>
        <h6 style="font-weight: bold" class="p-col-12">薪资待遇</h6>
        <div class="p-grid p-mt-1 p-col-12">

          <div class="p-field p-col">
            <label>最低薪资：</label>
            <InputNumber @blur="change10" v-model="from.minSalary" mode="currency" currency="CNY" currencyDisplay="code"
                         locale="zh-CN"></InputNumber>
            <span class="change">{{ mas10 }}</span>
          </div>
          <div class="p-field p-col">
            <label>最高薪资：</label>
            <InputNumber @blur="change11" v-model="from.maxSalary" mode="currency" currency="CNY" currencyDisplay="code"
                         locale="zh-CN"></InputNumber>
            <span class="change">{{ mas11 }}</span>
          </div>
          <label class="p-col-12">其他福利：</label>
          <div @blur="change4" class="p-grid p-ml-1 p-mt-2">
            <div class="p-field-checkbox p-mr-2 p-ml-2">
              <Checkbox id="city1" v-model="from.welfare" name="welfare" value="五险一金"/>
              <label for="city1">五险一金</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city2" v-model="from.welfare" name="welfare" value="技能培训"/>
              <label for="city2">技能培训</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city3" v-model="from.welfare" name="welfare" value="年度旅游"/>
              <label for="city3">年度旅游</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.welfare" name="welfare" value="带薪休假"/>
              <label>带薪休假</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.welfare" name="welfare" value="加班补助"/>
              <label>加班补助</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.welfare" name="welfare" value="节日福利"/>
              <label>节日福利</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.welfare" name="welfare" value="其他"/>
              <label>其他</label>
            </div>
          </div>
          <div class="p-col-12 p-ml-1">
            <span class="change">{{ mas4 }}</span>
          </div>

        </div>
        <h6 style="font-weight: bold" class="p-col-12">岗位要求：</h6>
        <div class="p-field p-col">
          <label>学历要求：</label>
          <Dropdown @blur="change5" v-model="from.degree" :options="degreeList" optionLabel="name"
                    option-value="name"
                    placeholder="学历要求" display="chip" showClear="true"/>
          <span class="change">{{ mas5 }}</span>
        </div>
        <div class="p-field p-col">
          <label>专业要求：</label>
          <InputText @blur="change6" v-model="from.major"></InputText>
          <span class="change">{{ mas6 }}</span>
        </div>
        <div class="p-field p-col">
          <label>性别要求：</label>
          <Dropdown @blur="change7" v-model="from.sex" :options="sexList" optionLabel="name"
                    option-value="name"
                    placeholder="性别要求" display="chip" showClear="true"/>
          <span class="change">{{ mas7 }}</span>
        </div>
        <div class="p-field p-col">
          <label>最小年龄 （岁）：</label>
          <InputText @blur="change8" v-model="from.minAge"></InputText>
          <span class="change">{{ mas8 }}</span>
        </div>
        <div class="p-field p-col">
          <label>最大年龄 （岁）：</label>
          <InputText @blur="change9" v-model="from.maxAge"></InputText>
          <span class="change">{{ mas9 }}</span>
        </div>
      </div>
      <div class="p-grid p-fluid ">
        <div class="p-field p-col-12 p-md-6 ">
          <label>工作经验 （年）：</label>
          <InputText v-model="from.workTime" type="text"/>
          <span class="change">{{ mas12 }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>开始时间：</label>
          <Calendar  class="p-ml-3" v-model="from.beginTime"
                     placeholder="开始日期" dateFormat="yy-mm-dd" showButtonBar="true"/>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>工作时间：</label>
          <InputNumber v-model="from.date"
                     placeholder="工作时间（天）" :useGrouping="false"/>
        </div>
        <div class="p-field p-col-12 ">
          <label>岗位职责：</label>
          <div>
            <textarea @blur="change14" v-model="from.duty" style="height: 100px" class="p-col-12"
                      field="name"></textarea>
            <span class="change">{{ mas14 }}</span>
          </div>
        </div>
        <div class="p-field p-col-12 ">
          <label>岗位要求：</label>
          <div>
            <textarea @blur="change15" v-model="from.request" style="height: 100px" class="p-col-12"
                      field="name"></textarea>
            <span class="change">{{ mas15 }}</span>
          </div>
        </div>
        <h6 style="font-weight: bold; margin-top: 0" class="p-col-12">负责人员：</h6>
        <div class="p-field p-col-12 p-md-6 ">
          <label>负责人员：</label>
          <InputText v-model="from.person" type="text"/>
          <span class="change">{{ mas16 }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>联系方式：</label>
          <InputText @blur="change17" v-model="from.phone"></InputText>
          <span class="change">{{ mas17 }}</span>
        </div>
      </div>

      <div class="p-field p-grid p-jc-evenly">
        <div>
          <Button @click="open()" label="取消" class="p-mr-2" icon="pi pi-fw pi-replay"/>
        </div>
        <div>
          <Button @click="submitPosition()" label="保存" icon="pi pi-fw pi-save"/>
        </div>
      </div>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      mas1: null,
      mas2: null,
      mas3: null,
      mas4: null,
      mas5: null,
      mas6: null,
      mas7: null,
      mas8: null,
      mas9: null,
      mas10: null,
      mas11: null,
      mas12: null,
      mas13: null,
      mas14: null,
      mas15: null,
      mas16: null,
      mas17: null,
      from: {
        code: null,
        job: null,
        degree: null,
        minSalary: null,
        maxSalary: null,
        recruitmentStatus: null,
        welfare: [],
        addr: null,
        phone: null,
        person: null,
        request: null,
        duty: null,
        workTime: null,
        maxAge: null,
        minAge: null,
        sex: null,
        major: null,
        beginTime:null,
        date:null,
      },
      showDialog:false,
      degreeList: [],
      sexList: []
    }
  },
  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'xueliyaoqiu'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.degreeList = resList;
    })
    this.$http.get('/dictionary/selectValue', {params: {key: 'xingbie'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.sexList = resList;
    })
  },
  methods: {
    init(){
      this.showDialog=true
    },
    closeDialog(){
      this.showDialog=false
      this.$emit('close')
    },
    change1(e) {
      let b = e.target.value.replace(/[\W]/g, '')
      if (b != e.target.value) {
        this.mas1 = "您输入格式有误，请重新输入"
      } else {
        this.mas1 = null;
      }
    },
    change2(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas2 = "您输入格式有误，请重新输入"
      } else {
        this.mas2 = null;
      }
    },
    change3() {
      this.mas3 = null;
    },
    change4() {
      this.mas4 = null;
    },
    change5() {
      this.mas5 = null;
    },
    change6() {
      this.mas6 = null;
    },
    change7() {
      this.mas7 = null;
    },
    change8(e) {
      let b = e.target.value.replace(/[^\d]/g, '')
      if (b != e.target.value) {
        this.mas8 = "您输入格式有误，请重新输入"
      } else {
        this.mas8 = null;
      }
    },
    change9(e) {
      let b = e.target.value.replace(/[^\d]/g, '')
      if (b != e.target.value) {
        this.mas9 = "您输入格式有误，请重新输入"
      } else {
        this.mas9 = null;
      }
    },
    change10() {
      this.mas10 = null;
      if(this.from.maxSalary!=null&&this.from.minSalary!=null  && this.from.maxSalary>this.from.minSalary){
        this.mas10=null
      } else if(this.from.maxSalary!=null&&this.from.minSalary!=null  && this.from.maxSalary<this.from.minSalary) {
        this.mas10 ='正确填写，最大薪资应大于最小薪资'
      }
    },
    change11() {
      if(this.from.maxSalary!=null&&this.from.minSalary!=null && this.from.maxSalary>this.from.minSalary){
        this.mas10=null
      } else if(this.from.maxSalary!=null&&this.from.minSalary!=null  && this.from.maxSalary<this.from.minSalary) {
        this.mas10 ='正确填写，最大薪资应大于最小薪资'
      }
    },

    change13() {
      this.mas13 = null;
    },
    change14() {
      this.mas14 = null;
    },
    change15() {
      this.mas15 = null;
    },
    change17(e) {
      let b = e.target.value.replace(/[^\d]/g, '')
      if (b != e.target.value) {
        this.mas17 = "您输入格式有误，请重新输入"
      } else {
        if (b.length != 11) {
          this.mas17 = "请正确的输入您的手机号"
        } else {
          this.mas17 = null;
        }
      }
    },
    submitPosition() {
      (this.from.job == null | this.from.job == "") ? this.mas2 = "必填项，请输入" : this.mas2 = null;
      (this.from.addr == null | this.from.addr == "") ? this.mas3 = "必填项，请输入" : this.mas3 = null;
      (this.from.minSalary == null | this.from.maxSalary == "") ? this.mas10 = "必填项，请输入" : this.mas10 = null;
      (this.from.maxSalary == null | this.from.maxSalary == "") ? this.mas11 = "必填项，请输入" : this.mas11 = null;
      (this.from.person == null | this.from.person == "") ? this.mas16 = "必填项，请输入" : this.mas16 = null;
      (this.from.phone == null | this.from.phone == "") ? this.mas17 = "必填项，请输入" : this.mas17 = null;

      if (this.from.job != null & this.from.job != "" &
          this.from.addr != null & this.from.addr != "" &
          this.from.minSalary != null & this.from.maxSalary != "" &
          this.from.maxSalary != null & this.from.maxSalary != "" &
          this.from.person != null & this.from.person != "" &
          this.from.phone != null & this.from.phone != "") {
        console.log(111)
        let list = this.from.welfare
        let ids = ""
        for (let i in list) {
          ids += list[i] + ","
        }
        ids = ids.substring(0, ids.length - 1)
        this.from.welfare = ids
        console.log(this.from.welfare)
        this.$http.put('/companies/addPosition', this.from).then(() => {
          this.$toast.add({
            severity: 'success', summary: '成功', detail: '职位添加成功！', life: 3000
          })
          this.showDialog=false
          this.$emit('close')
        })
      }
    },
    open() {
      this.$router.push('/recruit/recruitList')
    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}

</style>
